import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import category from "@/store/category";
import contentType from "@/store/contentType";
import site from "@/store/site";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    auth,
    category,
    contentType,
    site,
  },
  state: {
    snackBar: {},
    overlay: false,
  },
  getters: {},
  mutations: {
    SET_SNACKBAR: function (state, payload) {
      state.snackBar = payload;
    },
    SET_OVERLAY: function (state, payload) {
      state.overlay = payload;
    },
  },
  actions: {},
});
