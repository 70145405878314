import HomeView from "@/views/Home";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/Login"),
    meta: { guestOnly: true },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/auth/Signup"),
    meta: { guestOnly: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/auth/ForgotPassword"),
    meta: { guestOnly: true },
  },
  {
    path: "/auth/email/verify",
    name: "AuthEmailVerify",
    component: () => import("../views/auth/Verify"),
    meta: { authOnly: true },
  },
  {
    path: "/auth/reset-password",
    name: "AuthResetPassword",
    component: () => import("../views/auth/ResetPassword"),
    meta: { guestOnly: true },
  },
  {
    path: "/niche-categories",
    name: "NicheCategories",
    component: () => import("../views/NicheCategories"),
    meta: { authOnly: true },
  },
  {
    path: "/content-types",
    name: "ContentTypes",
    component: () => import("../views/ContentType"),
    meta: { authOnly: true },
  },
  {
    path: "/sites",
    name: "Sites",
    component: () => import("../views/Sites"),
    meta: { authOnly: true },
  },
];

export default routes;
