<template>
  <v-snackbar v-model="display" :timeout="timeout" :top="true" :color="type">
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="display = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: "SnackbarComponent",
  data() {
    return {
      text: "",
      display: false,
      timeout: 5000,
      type: "success",
    };
  },
  created() {
    this.$store.watch(
      (state) => state.snackBar,
      () => {
        const { snackBar } = this.$store.state;
        if (snackBar.text !== "") {
          this.display = true;
          this.text = snackBar.text;
          this.type = snackBar.type;
        }
      }
    );
  },
};
</script>
