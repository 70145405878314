import Api from "@/services/Api";
const all = (payload) => {
  const path = "/content-types";
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const get = (payload) => {
  const endpoint = `/content-type/${payload.id}`;
  return Api.getRequest({
    endpoint,
  });
};

const store = (payload) => {
  return Api.postRequest({
    endpoint: "/content-type/store",
    data: payload,
  });
};

const update = (payload) => {
  return Api.postRequest({
    endpoint: "/content-type/update",
    method: "PUT",
    data: payload,
  });
};

const remove = (payload) => {
  return Api.deleteRequest({
    endpoint: `/content-type/delete/${payload.id}`,
  });
};

const destroy = (payload) => {
  return Api.deleteRequest({
    endpoint: `/content-type/destroy/${payload.id}`,
  });
};

const restore = (payload) => {
  return Api.getRequest({
    endpoint: `/content-type/restore/${payload.id}`,
  });
};

export default {
  all,
  get,
  store,
  update,
  remove,
  destroy,
  restore,
};
