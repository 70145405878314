<template>
  <div>
    <v-app-bar elevation="0">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title>Rabbi IT Firm</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!authenticated"
        rounded
        elevation="0"
        color="indigo lighten-1"
        dark
        to="/login"
      >
        Login
      </v-btn>
      <v-menu
        v-if="authenticated"
        rounded="lg"
        offset-y
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            color="indigo lighten-1"
            class="white--text ma-5"
            v-bind="attrs"
            v-on="on"
            rounded
          >
            {{ user.name }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="item in userMenu"
            :key="item.title"
            link
            @click="userMenuHandler(item.route)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-btn
              elevation="0"
              :loading="isLogoutLoading"
              @click="logoutHandler"
            >
              Logout
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      style="height: 100vh"
      color="indigo lighten-1"
      v-if="authenticated"
      v-model="drawer"
      dark
      absolute
      temporary
    >
      <v-list-item>
        <v-img :src="require('../../assets/logo.png')" max-height="50" />
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <template v-for="(menu, key) in sidebarMenu">
          <v-list-group
            v-if="menu.items.length > 0"
            v-model="menu.active"
            :key="key"
            :prepend-icon="menu.icon"
            no-action
            color="grey lighten-1"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="menu.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in menu.items"
              :key="child.title"
              :to="child.action"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="menu.title" :to="menu.action" link>
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ menu.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            block
            dark
            color="indigo darken-4"
            :loading="isLogoutLoading"
            @click="logoutHandler"
            v-if="!mini"
          >
            Logout
          </v-btn>
          <v-btn icon v-else @click="logoutHandler">
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Auth from "@/services/Auth";
export default {
  name: "HeaderComponent",
  data() {
    return {
      drawer: false,
      isLogoutLoading: false,
      sidebarMenu: [
        {
          icon: "mdi-view-dashboard",
          active: true,
          items: [],
          action: "/",
          title: "Dashboard",
        },
        { title: "Sites", icon: "mdi-web", items: [], action: "/sites" },
        {
          icon: "mdi-cog",
          title: "Settings",
          items: [
            {
              title: "Niche Categories",
              icon: "mdi-content-duplicate",
              action: "/niche-categories",
            },
            {
              title: "Content Types",
              icon: "mdi-format-list-bulleted-type",
              action: "/content-types",
            },
            {
              title: "Order Types",
              icon: "mdi-reorder-vertical",
              action: "/order-types",
            },
            { title: "Services", icon: "mdi-network", action: "/services" },
          ],
        },
      ],
      userMenu: [
        { title: "My Account", route: "/" },
        { title: "Settings", route: "/" },
      ],
      mini: false,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    async logoutHandler() {
      this.isLogoutLoading = true;
      await Auth.logout()
        .then(({ data: { message } }) => {
          this.setSnackbar({ text: message, type: "info" });
        })
        .catch(({ response: { data } }) => {
          this.setSnackbar({ text: data.message, type: "error" });
        })
        .finally(() => {
          this.logout();
          this.isLogoutLoading = false;
        });
    },
    setSnackbar({ text, type }) {
      this.$store.commit("SET_SNACKBAR", {
        type: type,
        text: text,
      });
    },
    userMenuHandler(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 25px !important;
}
</style>
