import axios from "axios";
import store from "@/store";
import router from "@/router";
let api = process.env.VUE_APP_API_BASE_URL;
let api2 = process.env.VUE_APP_BASE_URL;

let Api = axios.create({
  baseURL: api,
});

let Api2 = axios.create({
  baseURL: api2,
});

Api.defaults.withCredentials = true;
Api2.defaults.withCredentials = true;

Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      store.commit("SET_SNACKBAR", {
        text: "Network Error",
        type: "error",
      });
      return error;
    }
    // all the other error responses
    switch (error.response.status) {
      case 400:
        store.commit("SET_SNACKBAR", {
          text: error.message,
          type: "error",
        });
        break;

      case 401: // authentication error, refresh user token
        // eslint-disable-next-line no-case-declarations
        /*const {
          data: { token },
        } = await Api({
          url: "/refresh",
          method: "POST",
          headers: getAuthorizationHeader(),
          data: [],
        });*/
        // eslint-disable-next-line no-debugger
        store.commit("auth/SET_TOKEN", "");
        store.commit("auth/SET_AUTHENTICATED", false);
        store.commit("auth/SET_USER", {});
        await router.push("/login");
        break;

      default:
        store.commit("SET_SNACKBAR", {
          text: error.message,
          type: "error",
        });
    }

    return error;
  }
);

const getAuthorizationHeader = () => {
  const token = store.state.auth.token;
  const header = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };
  if (token && token) {
    header["Authorization"] = `Bearer ${token}`;
  }
  return header;
};

const getRequest = async function (payload) {
  return Api({
    url: payload.endpoint,
    method: "GET",
    headers: getAuthorizationHeader(),
  });
};

const postRequest = async function (payload) {
  let data = payload.data;
  return Api({
    url: payload.endpoint,
    method: payload.method || "POST",
    headers: getAuthorizationHeader(),
    data: data,
  });
};

const deleteRequest = async function (payload) {
  return Api({
    url: payload.endpoint,
    method: "DELETE",
    headers: getAuthorizationHeader(),
  });
};

const getRequestWithoutBaseApi = async function (payload) {
  return Api2({
    url: payload.endpoint,
    method: "GET",
    headers: getAuthorizationHeader(),
  });
};

export default {
  getRequest,
  postRequest,
  deleteRequest,
  getRequestWithoutBaseApi,
};
