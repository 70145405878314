import ContentType from "@/services/ContentType";
export default {
  namespaced: true,
  state: {
    contentTypes: [],
  },
  getters: {
    contentTypes(state) {
      return state.contentTypes;
    },
  },
  mutations: {
    SET_CONTENT_TYPES(state, payload) {
      state.contentTypes = payload;
    },
  },
  actions: {
    async all({ commit }, payload) {
      const {
        data: { data },
      } = await ContentType.all(payload);

      commit("SET_CONTENT_TYPES", data);
    },
    // eslint-disable-next-line no-unused-vars,no-empty-pattern
    async get({}, payload) {
      return ContentType.get(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async store({}, payload) {
      return ContentType.store(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async update({}, payload) {
      return ContentType.update(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async remove({}, payload) {
      return ContentType.remove(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async destroy({}, payload) {
      return ContentType.destroy(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async restore({}, payload) {
      return ContentType.restore(payload);
    },
  },
};
