<template>
  <div class="text-center">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
export default {
  name: "GOverlay",
  data() {
    return {
      overlay: false,
    };
  },
  created() {
    this.$store.watch(
      (state) => state.overlay,
      () => {
        const { overlay } = this.$store.state;
        this.overlay = overlay;
      }
    );
  },
};
</script>
