<template>
  <v-app>
    <snackbar-component />
    <GOverlay />
    <v-container>
      <header-component />
    </v-container>
    <v-container>
      <v-main>
        <verification-component />
        <router-view />
      </v-main>
    </v-container>
    <v-footer dark padless class="indigo lighten-1 white--text text-center">
      <v-container>
        <v-card flat tile class="indigo lighten-1 white--text text-center">
          <v-card-text>
            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-4 white--text"
              icon
            >
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            {{ new Date().getFullYear() }} — <strong>Rabbi IT Firm</strong>
          </v-card-text>
        </v-card>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import SnackbarComponent from "@/components/Snackbar";
import HeaderComponent from "@/components/menu/Header";
import GOverlay from "@/components/GOverlay";
import VerificationComponent from "@/components/VerificationComponent";
export default {
  name: "App",
  metaInfo: {
    title: "Guest Post App",
    titleTemplate: "App | %s",
    meta: [
      { name: "robots", content: "noindex" },
      { name: "googlebot", content: "noindex" },
    ],
  },
  components: {
    VerificationComponent,
    GOverlay,
    HeaderComponent,
    SnackbarComponent,
  },
  data: () => ({
    icons: ["mdi-home", "mdi-email", "mdi-calendar", "mdi-delete"],
  }),
};
</script>

<style lang="scss">
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #fff !important;
}
.v-main {
  padding: 32px 0 96px 0 !important;
}

.text-transform-capitalize {
  text-transform: capitalize !important;
  font-size: 18px !important;
}
</style>
