<template>
  <v-row class="mb-10" v-if="authenticated && !verified">
    <v-col cols="12" md="8" offset-md="2">
      <v-card>
        <v-card-title class="text-h5">
          Please Verify your account
        </v-card-title>
        <v-card-subtitle>
          We have sent you a account verification link to your email, please
          verify your account otherwise your account will blocked/removed after
          7 days. If your are not received a email from us, Please click the
          link bellow and resend verification email.
        </v-card-subtitle>
        <v-card-actions>
          <v-btn text @click="resendHandler()"> Resend Link </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Auth from "@/services/Auth";
export default {
  name: "VerificationComponent",
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated,
      verified: (state) => state.auth.verified,
    }),
  },
  methods: {
    async resendHandler() {
      this.$store.commit("SET_OVERLAY", true);
      await Auth.resend()
        .then(({ data: { message } }) => {
          this.setSnackbar({ text: message, type: "info" });
        })
        .catch(({ response: { data } }) => {
          this.setSnackbar({ text: data.message, type: "error" });
        })
        .finally(() => {
          this.$store.commit("SET_OVERLAY", false);
        });
    },
    setSnackbar({ text, type }) {
      this.$store.commit("SET_SNACKBAR", { type, text });
    },
  },
};
</script>

<style scoped></style>
