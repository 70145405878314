<template>
  <g-card :show-page-title="false" :elevation="0"> </g-card>
</template>

<script>
import GCard from "@/components/GCard";

export default {
  name: "HomePage",
  metaInfo: {
    title: "Guest Post Admin",
    titleTemplate: "Home | %s",
    meta: [
      { name: "robots", content: "noindex" },
      { name: "googlebot", content: "noindex" },
    ],
  },
  components: {
    GCard,
  },
};
</script>
